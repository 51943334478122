<template>
    <div v-if="false"></div>
</template>
<script>
import { refreshTokenAPI } from '@/assets/javascripts/api.js'
export default {
    name: 'RefreshToken',
    methods: {
        async getToken() {
            const refreshRes = await refreshTokenAPI()
            this.$store.state.getTokenTime = Date.now()
            const tokenType = refreshRes.data.token_type
            const accessToken = refreshRes.data.access_token
            const refreshToken = refreshRes.data.refresh_token
            const authorization = `${tokenType} ${accessToken}`
            this.$store.commit('set', {
                token: authorization,
                refreshToken: refreshToken
            })

            this.setTimer()
            this.timeout.shift()
        },
        getRefreshTime() {
            const tokenExpireTime = this.$store.state.tokenExpireTime
            const time = this.$store.state.getTokenTime + tokenExpireTime * 1000
            const now = Date.now()
            return time - now
        },
        checkVisible() {
            if (document.visibilityState === 'visible') {
                for (let i = 0; i < this.timeout.length; i++) {
                    clearTimeout(this.timeout[i])
                }
                this.timeout = []
                this.setTimer()
            }
        },
        setTimer() {
            const timer = this.getRefreshTime()
            this.timeout.push(
                setTimeout(() => {
                    this.getToken()
                }, timer)
            )
        }
    },
    data() {
        return {
            timeout: []
        }
    },
    mounted() {
        this.setTimer()
        document.addEventListener('visibilitychange', this.checkVisible)
    },
    unmounted() {
        document.removeEventListener('visibilitychange', this.checkVisible)
    }
}
</script>
